import { render, staticRenderFns } from "./TournamentParticipants.vue?vue&type=template&id=ab50fb6a&scoped=true&"
import script from "./TournamentParticipants.vue?vue&type=script&lang=js&"
export * from "./TournamentParticipants.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab50fb6a",
  null
  
)

export default component.exports